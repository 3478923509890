<template>
  <div class="lg:px-auto container px-gap">
    <div v-if="modules.cards" class="-mx-gap flex flex-wrap md:-mx-gap2">
      <CardsTheCard
        v-for="(card, index) in modules.cards"
        :key="index"
        :card="card.fields"
      />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      modules: {
        type: Object,
        required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .module--animated-cards {
    .card--full {
      @apply h-[537px] pb-0 transition-all duration-500 ease-in-out;
      :deep(.card__copy-wrap) {
        @apply max-h-full px-2 pb-2 opacity-100;
        p {
          @apply mb-0;
        }
      }
      @screen lg {
        @apply w-6/12;

        :deep(.card__copy-wrap) {
          p {
            @apply max-h-0 opacity-0;
          }
        }
        &:hover {
          @apply z-20 scale-110;
          :deep(.card__copy-wrap) {
            p {
              @apply max-h-full opacity-100;
            }
          }
        }
      }
      &:hover {
        .card__zoom-bg {
          transform: none;
        }
      }
    }
  }
</style>
