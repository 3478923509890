<template>
  <component
    :is="component"
    v-if="props.modules"
    :class="classes"
    :modules="props.modules"
  />
</template>

<script setup>
  import { resolveComponent } from 'vue';

  const props = defineProps({
    modules: {
      type: Object,
      required: true,
    },
  });

  const componentMap = {
    'Mobile Navigation': resolveComponent('HeadersMobileNavigation'),
    'Dash Cams Quote': resolveComponent('TypographyDashCamsQuote'),
    'Social Share': defineAsyncComponent(
      () => import('../widgets/SocialShare.vue')
    ),
    'Background Image Tabs': resolveComponent('TabsBackgroundImageTabs'),
    'Top Fleets Hub': resolveComponent('WidgetsTopFleetsHub'),
    'Top Fleets Sub Hero': resolveComponent('HeroesTopFleetsHero'),
    'Top Fleets Stats': resolveComponent('WidgetsTopFleetsStats'),
    'Audio Quote': resolveComponent('TypographyAudioQuote'),
    'CW Image Grid': resolveComponent('ImagesCwImageGrid'),
    'Index Solution Carousel': resolveComponent('CarouselsIndexSolutions'),
    'Platform Data Quote': resolveComponent('TypographyPlatformQuote'),
    'CW Sticky Scroll': resolveComponent('WidgetsCwStickyScroll'),
    'Index Case Studies Carousel': resolveComponent(
      'CarouselsIndexCaseStudies'
    ),
    'Solutions Content Scroll': resolveComponent(
      'WidgetsSolutionsContentScroll'
    ),
    'Solutions Hero': resolveComponent('HeroesSolutionsHero'),
    'Solutions Benefits': resolveComponent('WidgetsSolutionsBenefits'),
    'Solutions Image Column': resolveComponent('ImagesSolutionsImageColumn'),
    'Platform Data Carousel': resolveComponent('CarouselsPlatformDataSlider'),
    'Dash Cam Carousel': resolveComponent('CarouselsDashCamCarousel'),
    'Platform Modal Slider': resolveComponent('CarouselsPlatformModalSlider'),
    'Video Modal': resolveComponent('ModalsVideoModal'),
    'Half Video': resolveComponent('VideosHalfVideo'),
    'Numbered Content Scroll': resolveComponent('WidgetsNumberedContentScroll'),
    'Code Module': resolveComponent('WidgetsCodeModule'),
    'Office Slider': resolveComponent('CarouselsOfficeSlider'),
    'Our History Slider': resolveComponent('CarouselsHistorySlider'),
    'Leadership Shuffle': resolveComponent('CarouselsTheLeadershipShuffle'),
    'Leadership Page': resolveComponent('TemplatesTheLeadershipPage'),
    'Product Group': resolveComponent('WidgetsProductGroup'),
    'Accordion Tabs with Select': defineAsyncComponent(
      () => import('../tabs/DevAccordionTabsSelect.vue')
    ),
    'Accordion Tabs with Hotspot': defineAsyncComponent(
      () => import('../tabs/DevAccordionTabsHotspot.vue')
    ),
    'Accordion Tabs': defineAsyncComponent(
      () => import('../tabs/DevAccordionTabs.vue')
    ),
    'Animated Cards': resolveComponent('CardsAnimatedCards'),
    'Related Stories': resolveComponent('CarouselsRelatedStoriesSlider'),
    'Products Rotator': resolveComponent('CarouselsProductsRotator'),
    'Products How it Works': resolveComponent('WidgetsProductsHowItWorks'),
    'Index News': resolveComponent('WidgetsIndexNews'),
    'Careers Filters Listing': resolveComponent(
      'FiltersDevCareersRolesFilters'
    ),
  };

  const typeClassMap = {
    'Dash Cams Quote': 'module--cams-quote relative',
    'Social Share': 'module--social-share',
    'Background Image Tabs': 'module--background-tabs',
    'Top Fleets Hub': 'module--top-fleets-hub',
    'Top Fleets Sub Hero': 'module--top-fleets-hero relative',
    'Top Fleets Stats': 'module--top-fleets-stats relative',
    'Audio Quote': 'module--audio-quote relative',
    'CW Image Grid': 'module--cw-image-grid relative',
    'Index Solution Carousel': 'module--solution-carousel relative',
    'Platform Data Quote': 'module--platform-quote relative',
    'CW Sticky Scroll': 'module--sticky-scroll relative',
    'Index Case Studies Carousel': 'module--index-case-carousel relative',
    'Solutions Content Scroll': 'module--solutions-content-scroll relative',
    'Solutions Hero': 'module--index-solutions-hero relative',
    'Solutions Benefits': 'module--index-solutions-benefits relative',
    'Solutions Image Column': 'module--index-solutions-image-column relative',
    'Platform Data Carousel': 'module--platform-data-carousel relative',
    'Dash Cam Carousel': '',
    'Platform Modal Slider': 'module--platform-modal-carousel relative',
    'Video Modal': 'module--video-modal relative',
    'Half Video': 'module--half-video relative',
    'Numbered Content Scroll':
      'module module--numbered-content-scroll relative',
    'Code Module': 'module module--code-module relative',
    'Office Slider': 'module module--office-slider relative',
    'Our History Slider': 'module module--history-slider relative',
    'Leadership Shuffle': 'module module--leadership-shuffle relative',
    'Leadership Page': 'module module--leadership-page relative',
    'Product Group': 'module module--product-group relative',
    'Accordion Tabs': 'module module--accordion-tabs relative',
    'Accordion Tabs with Select':
      'module module--accordion-tabs-select relative',
    'Accordion Tabs with Hotspot': 'module--accordion-tabs-hotspot relative',
    'Animated Cards': 'module module--animated-cards relative',
    'Related Stories': 'module module--related-stories relative',
    'Products Rotator': 'module module--products-rotator relative',
    'Products How it Works': 'module module--how-it-works relative',
    'Index News': 'module module--index-news relative',
    'Mobile Navigation': 'module module--mobile-nav relative',
    'Careers Filters Listing': 'module module--roles-filters-listing relative',
  };

  const component = computed(() => componentMap[props.modules.type]);

  const classTypes = computed(
    () =>
      `module ${typeClassMap[props.modules.type] ? typeClassMap[props.modules.type] : ''}`
  );

  const classes = computed(() => {
    return classTypes.value;
  });
</script>
